import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import tw from 'twin.macro'

import BackgroundImage from 'gatsby-background-image'
import { FullscreenBackgroundWrapper } from './styles'

/**
 * In this functional component a fullscreen <BackgroundImage />  is created.
 * @param className   string    className(s) from styled-components.
 * @param children    nodes     Child-components.
 * @return {*}
 * @constructor
 */
const FullBackground = ({ className, children }) => {
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "umbrella-splash-bg.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 2200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Single Image Data
  const imageData = desktop.childImageSharp.fluid

  return (
    <FullscreenBackgroundWrapper>
      <BackgroundImage
        Tag="div"
        tw="absolute w-full h-full"
        className={className || null}
        fluid={imageData}
        backgroundColor="#040e18"
        title="Fullscreen Background"
        id="fullscreenbg"
        role="img"
        aria-label="Fullscreen Background"
        preserveStackingContext
      >
        {children}
      </BackgroundImage>
    </FullscreenBackgroundWrapper>
  )
}

export default FullBackground
