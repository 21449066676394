import React from 'react'
import { navigate } from 'gatsby'
import { Formik, Form, Field, ErrorMessage, isObject } from 'formik'
import tw, { styled, css } from 'twin.macro'
import { loginInitialValues, loginValidationSchema } from '../forms'
import FullBg from '../components/FullBackground'
import SEO from '../components/SEO'
import Button from '../components/Button'
import Header from '../components/Header'
import Footer from '../components/Footer'

import { BgSlanted, FlexContainer } from '../components/styles'
import { FormControl, Row, InputError, input, label } from '../components/FormControls'

import useFetch from '../hooks/useFetch'

const Container = styled.div`
  ${tw`w-screen lg:w-3/5 lg:h-screen z-10`}
  ${({ theme }) => theme.mq('lg')} {
    filter: drop-shadow(0 0 0.2vw hsla(0 0% 0% / 0.4)) drop-shadow(0 0 4vw hsla(0 0% 0% / 0.3));
  }
`

const CenterLine = styled.div`
  position: relative;
  ${({ theme }) => theme.mq('md')} {
    &:after {
      content: '';
      background: ${({ theme }) => theme.colors.green.default};
      height: 2px;
      width: 70%;
      border-radius: 3px;
      position: absolute;
      top: 50%;
      margin: 0 0.5em;
    }
  }
`

const TextWrapper = styled.div`
  float: right;
  position: relative;
  height: 100%;
  width: 8vw;
  shape-outside: polygon(80% 0%, 100% 0%, 100% 100%, 0% 100%);
`

const successCallback = res => {
  console.log('RESULTS', res)
  navigate('/confirm-email/', { state: res })
}

const errorCallback = res => {
  navigate('/guardian-information/', { state: { response: res } })
}

const IndexPage = () => {
  const { setFetchOptions, isLoading } = useFetch()

  const handleSubmit = async body => {
    console.log('Logging in', body)

    setFetchOptions({
      method: 'POST',
      endpoint: '/.netlify/functions/login',
      body,
      successCallback,
      errorCallback,
    })
  }

  return (
    <FlexContainer tw="p-0 md:p-0">
      <Header tw="bg-white lg:bg-transparent lg:absolute lg:z-20 -mb-1 p-4 md:p-6 lg:p-8" />

      <main tw="flex-auto">
        <SEO title="Child Care Requirements" />

        <FullBg />

        <Container>
          <TextWrapper />

          <BgSlanted>
            <div tw="flex flex-col justify-center p-4 md:p-8 lg:h-screen">
              <div tw="relative h-auto max-w-4xl">
                <h1
                  tw="inline-block text-4xl lg:text-6xl xl:text-6xl font-bold text-purple mb-4"
                  css={{ lineHeight: 0.9 }}
                >
                  <span tw="whitespace-nowrap">Request</span> Child Care
                </h1>
                {/* <p tw="text-sm font-bold">Date of reopening – September 4, 2020</p> */}
                <p tw="pb-4 sm:pt-4 md:text-lg">
                  This online portal will allow you to request care at any of our centres. You will fill out your information, including choice of location, program, schedule, and when you would like to start. After seeing your request come in, staff will get back to you to advise about availability/next steps.
                </p>

                <Formik
                  initialValues={loginInitialValues}
                  validationSchema={loginValidationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form name="login" noValidate>
                    <div hidden aria-hidden="true">
                      <label htmlFor="human">
                        Don’t fill this out if you're human:
                        <Field id="human" name="botField" />
                      </label>
                    </div>

                    <Row>
                      <FormControl tw="w-auto">
                        <label css={label} htmlFor="email">
                          Email Address
                        </label>
                        <Field id="email" name="email" type="text" css={input} required />
                        <ErrorMessage name="email" component={InputError} />
                      </FormControl>
                      <Button isPrimary type="submit" tw="mt-2" isLoading={isLoading}>
                        Get Started
                      </Button>
                    </Row>
                  </Form>
                </Formik>
              </div>
            </div>
          </BgSlanted>
        </Container>
      </main>

      <Footer tw="text-center text-white lg:text-right lg:absolute lg:justify-end lg:bottom-0 lg:right-0" />
    </FlexContainer>
  )
}

export default IndexPage
